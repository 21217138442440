import Api, { FetchMethodType } from './Api';

export enum ApplyStep {
  PersonalDetails = 'personalDetails',
  EmploymentDetails = 'employmentDetails',
  References = 'references',
  UploadIncomeVerification = 'incomeVerification',
  UploadLoanInformation = 'loanInformation',
}

export type ApplyProgress = {
  [ApplyStep.PersonalDetails]: boolean;
  [ApplyStep.EmploymentDetails]: boolean;
  [ApplyStep.References]: boolean;
  [ApplyStep.UploadIncomeVerification]: boolean;
  [ApplyStep.UploadLoanInformation]: boolean;
};

export const defaultApplyProgress: Readonly<ApplyProgress> = Object.freeze({
  [ApplyStep.PersonalDetails]: false,
  [ApplyStep.EmploymentDetails]: false,
  [ApplyStep.References]: false,
  [ApplyStep.UploadIncomeVerification]: false,
  [ApplyStep.UploadLoanInformation]: false,
});

export enum KnownEmployerProfitStatus {
  ForProfit = 'For Profit',
  NonProfit = 'Non Profit',
  Unknown = 'Unknown',
}

export interface StudentLoanAssistanceData {
  eligible: boolean;
  paymentStartDate?: string;
  nonProfitStartDate: string | null;
  knownEmployerProfitStatus: KnownEmployerProfitStatus;
  allLoansMohela?: boolean;
  hasMonthlyPayments?: boolean;
  maxTerm?: number;
  totalBalance?: number;
  monthSavings?: number;
  currentPath?: PayoffData;
  recommendedPath?: PayoffData;

  householdSize?: number;
  undergraduateLoansPercentage?: number;
  enrolledIncomeDrivenRepayment?: boolean;
}

export interface PayoffData {
  monthsToDebtFree: number;
  monthlyPayment: number;
  totalPayment: number;
  payments: number[];
}

export interface StudentLoanCalculationParameters {
  forProfit?: boolean | null;
  nonProfitStartDate?: string;
  householdSize?: number;
  undergraduateLoansPercentage?: number;
  enrolledIncomeDrivenRepayment?: boolean;
}

export interface StudentLoanScheduleData {
  eventUri?: string;
  eventStartTime?: string;
  eventHost?: string;
  eventHostEmail?: string;
  eventName?: string;
  studentLoanData?: StudentLoanAssistanceData;
}

export interface PersonalReference {
  firstName?: string;
  lastName?: string;
  relationship?: Relationship;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phoneNumber?: string;
}

export enum Relationship {
  Mother = 'mother',
  Father = 'father',
  Child = 'child',
  Friend = 'friend',
  Sister = 'sister',
  Brother = 'brother',
  OtherFamilyMember = 'other family member',
}

export enum IncomeTaxFilingStatus {
  Single = 'Single',
  MarriedFilingJointly = 'Married filing jointly',
  MarriedFilingSeparately = 'Married filing separately',
  HeadOfHousehold = 'Head of household',
  QualifyingWidowerWithDependentChild = 'Qualifying widow(er) with dependent child',
}

export interface ApplicationData {
  employerName?: string;
  hrContactName?: string;
  hrContactEmail?: string;
  familySize?: number;
  adjustedGrossIncome?: number;
  incomeTaxFilingStatus?: IncomeTaxFilingStatus;
  driversLicenseState?: string;
  driversLicenseNumber?: string;
  reference1?: PersonalReference;
  reference2?: PersonalReference;
  applyProgress?: ApplyProgress | null;
}

export interface UploadStudentAidDataResponse {
  applyProgress?: ApplyProgress | null;
}
export interface StudentLoanApi {
  getStudentLoanAssistanceData(applicationId: string): Promise<StudentLoanAssistanceData>;
  setStudentLoanAssistanceSchedule(applicationId: string, eventUri?: string): Promise<StudentLoanScheduleData>;
  updateStudentLoanAssistantData(
    applicationId: string,
    data: StudentLoanCalculationParameters,
  ): Promise<StudentLoanAssistanceData>;
  getApplication(applicationId: string): Promise<ApplicationData>;
  updateApplication(applicationId: string, data: ApplicationData): Promise<ApplicationData>;
  uploadStudentAidData(applicationId: string, file: File): Promise<UploadStudentAidDataResponse>;
  uploadPaystubs(applicationId: string, files: File[]): Promise<UploadStudentAidDataResponse>;
}

export default class StudentLoanRestApi extends Api implements StudentLoanApi {
  async getStudentLoanAssistanceData(applicationId: string): Promise<StudentLoanAssistanceData> {
    return this.fetch<StudentLoanAssistanceData>(`/studentLoan/assistanceData/${applicationId}`);
  }

  async setStudentLoanAssistanceSchedule(applicationId: string, eventUri?: string): Promise<StudentLoanScheduleData> {
    return this.fetch(`/studentLoan/schedule/${applicationId}/`, {
      method: FetchMethodType.POST,
      body: { eventUri },
    });
  }

  async updateStudentLoanAssistantData(
    applicationId: string,
    data: StudentLoanCalculationParameters,
  ): Promise<StudentLoanAssistanceData> {
    return this.fetch<StudentLoanAssistanceData>(`/studentLoan/assistanceData/${applicationId}`, {
      method: FetchMethodType.PUT,
      body: data,
    });
  }

  async getApplication(applicationId: string): Promise<ApplicationData> {
    return this.fetch(`/studentLoan/application/${applicationId}`, {
      method: FetchMethodType.GET,
    });
  }

  async updateApplication(applicationId: string, data: ApplicationData): Promise<ApplicationData> {
    return this.fetch<ApplicationData>(`/studentLoan/application/${applicationId}`, {
      method: FetchMethodType.PUT,
      body: data,
    });
  }

  async uploadStudentAidData(applicationId: string, file: File): Promise<UploadStudentAidDataResponse> {
    const formData = new FormData();

    formData.append('files', file);
    formData.append('applicationId', applicationId);

    const result = await this.fetch<UploadStudentAidDataResponse>(
      `/studentLoan/application/${applicationId}/studentAidData`,
      {
        method: FetchMethodType.POST,
        body: formData,
        resetDefaultHeaders: true,
      },
    );

    return result;
  }

  async uploadPaystubs(applicationId: string, files: File[]): Promise<UploadStudentAidDataResponse> {
    const formData = new FormData();

    files.map((file) => formData.append('files', file));

    formData.append('applicationId', applicationId);

    const result = await this.fetch<UploadStudentAidDataResponse>(
      `/studentLoan/application/${applicationId}/paystubs`,
      {
        method: FetchMethodType.POST,
        body: formData,
        resetDefaultHeaders: true,
      },
    );

    return result;
  }
}
